import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CherrypalmLogoHorizontalImg = () => {
  const data = useStaticQuery(graphql`
    query {
      cherrypalmLogoHorizontalImg: file(relativePath: { eq: "cherrypalm_logo_horizontal.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data.cherrypalmLogoHorizontalImg.childImageSharp.fluid} />;
};

export default CherrypalmLogoHorizontalImg;
