import React, { Component } from 'react';
import styled from 'styled-components';
import { FiMenu } from 'react-icons/fi';
import { breakpoints, colors } from '../../utils/constants';

const MenuIcon = styled.div`
  margin: 0 1rem;
  display: inline-block;
  padding: 5px;
  text-align: end;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const MenuItem = styled.div`
  padding: 0.5rem;
  text-align: right;
`;

const StyledLink = styled.a`
  text-decoration: none;
  transition: 500ms;

  &:hover {
    color: ${colors.cpOrange};
  }
`;

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };

    this.toggleIsMenuOpen = this.toggleIsMenuOpen.bind(this);
  }

  toggleIsMenuOpen() {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen,
    }));
  }

  render() {
    const { isMenuOpen } = this.state;

    const navLinks = [
      { id: 1, link: '#home', text: 'Home' },
      { id: 2, link: '#about', text: 'About' },
      { id: 3, link: '#services', text: 'Services' },
      { id: 4, link: '#projects', text: 'Projects' },
    ];

    return (
      <MenuIcon onClick={this.toggleIsMenuOpen}>
        <FiMenu size="2rem" />
        {isMenuOpen && (
          <div>
            {navLinks.map(el => (
              <MenuItem key={el.id}>
                <StyledLink href={el.link}>{el.text}</StyledLink>
              </MenuItem>
            ))}
          </div>
        )}
      </MenuIcon>
    );
  }
}

export default HeaderMenu;
