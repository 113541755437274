import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CherrypalmLogoImg = () => {
  const data = useStaticQuery(graphql`
    query {
      cherrypalmLogoImg: file(relativePath: { eq: "cherrypalm_logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data.cherrypalmLogoImg.childImageSharp.fluid} />;
};

export default CherrypalmLogoImg;
