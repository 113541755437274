import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const LaptopGncImg = () => {
  const data = useStaticQuery(graphql`
    query {
        laptopGncImg: file(relativePath: { eq: "laptop_gnc.png" }) {
            childImageSharp {
                fluid(maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
  `);

  return <Img fluid={data.laptopGncImg.childImageSharp.fluid} />;
};

export default LaptopGncImg;
