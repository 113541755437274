export const colors = {
  cpOrange: 'rgba(242, 97, 33, 255)',
  cpBlue: 'rgba(0, 72, 119, 255)',
};

export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};
