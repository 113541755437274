import React from 'react';
import styled from 'styled-components';
import { CherrypalmLogoDarkGreyscaleImg, FooterBg1Img, FooterBg2Img } from '../img';
import { breakpoints, colors } from '../../utils/constants';

const FooterContainer = styled.footer`
  background-color: #111;
  color: #d6d6d6;
  padding: 5rem 2rem 1rem;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    background: #111;
  }

  ::after {
    content: '';
    background: #111;
  }
`;

const FooterItem = styled.div`
  width: 350px;
  line-height: 1.9;

  h3 {
    margin: 0;
  }

  @media (max-width: ${breakpoints.lg}) {
    margin-bottom: 1rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLink = styled.a`
  color: #999;
  text-decoration: none;
  transition: 500ms;

  &:hover {
    color: ${colors.cpOrange};
  }

  &::after {
    content: '-';
    margin: 0 0.5rem;

    @media (max-width: ${breakpoints.sm}) {
      content: '';
    }
  }

  &:last-of-type::after {
    content: '';
  }
`;

const HeaderUnderline = styled.div`
  width: 50px;
  height: 3px;
  background: ${colors.cpOrange};
  margin: 0.5rem 0 2rem;
`;

const BgImgWrapper = styled.div`
  position: absolute;
  width: 200px;
  z-index: -1;
`;

const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

const StyledHeader = styled.h3`
  color: #fff;
`;

const CopyrightContainer = styled.div`
  text-align: center;

  @media (max-width: ${breakpoints.lg}) {
    margin-bottom: 1rem;
  }
`;

const emojiMargin = {
  margin: '0 0.5rem',
};

const copyrightMargin = {
  margin: '5rem 0 0 0',
};

const blockDisplay = {
  display: 'block',
};

const Footer = () => (
  <FooterContainer>
    <FlexContainer>
      <FooterItem style={{ marginTop: '-30px' }}>
        <CherrypalmLogoDarkGreyscaleImg />
      </FooterItem>
      <FooterItem>
        <StyledHeader>California</StyledHeader>
        <HeaderUnderline />
        <p>
          12403 Central Ave
          <br />
          Suite 763
          <br />
          Chino, CA 91710
        </p>
      </FooterItem>

      <FooterItem>
        <BgImgWrapper style={{ left: 0, top: 0 }}>
          <FooterBg1Img />
        </BgImgWrapper>
        <BgImgWrapper style={{ right: 0, bottom: 0 }}>
          <FooterBg2Img />
        </BgImgWrapper>
        <StyledHeader>Contact</StyledHeader>
        <HeaderUnderline />
        <StyledLink href="mailto:info@cherrypalmsoftware.com" style={blockDisplay}>
          info@cherrypalmsoftware.com
        </StyledLink>
        <StyledLink href="tel:909-529-2772" style={blockDisplay}>
          (909) 529-2772
        </StyledLink>
      </FooterItem>
    </FlexContainer>

    <FlexContainer style={copyrightMargin}>
      <CopyrightContainer>
        <span role="img" aria-label="Cherrypalm" style={emojiMargin}>
          🍒🌴
        </span>
        {`© ${new Date().getFullYear()}`}
        <div>
          <StyledLink href="https://www.cherrypalmsoftware.com">Cherrypalm Software LLC</StyledLink>
        </div>
      </CopyrightContainer>
      <FooterLinkContainer>
        <StyledLink href="/terms">Terms & Conditions</StyledLink>
        <StyledLink href="/privacy">Privacy Policy</StyledLink>
      </FooterLinkContainer>
    </FlexContainer>
  </FooterContainer>
);

export default Footer;
