import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { CherrypalmLogoHorizontalImg } from '../img';

import { breakpoints, colors } from '../../utils/constants';
import HeaderMenu from './HeaderMenu';

const StyledHeader = styled.header`
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  width: 350px;
`;

const LinkContainer = styled.div`
  margin: 1rem 0;

  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const CallNowBtn = styled.a`
  align-self: center;
  border: 1px solid ${colors.cpOrange};
  border-radius: 4px;
  color: ${colors.cpOrange};
  padding: 0.5rem 1rem;
  text-decoration: none;
  white-space: nowrap;

  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const StyledLink = styled(props => <Link {...props} />)`
  position: relative;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  margin: 0 1rem;

  &:hover {
    color: ${colors.cpOrange};
    transition: color 300ms ease;

    &::before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${colors.cpOrange};
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
`;

const Header = () => {
  const navLinks = [
    { id: 1, text: 'Home', link: '#home' },
    { id: 2, text: 'About', link: '#about' },
    { id: 3, text: 'Services', link: '#services' },
    { id: 4, text: 'Projects', link: '#projects' },
  ];

  return (
    <StyledHeader>
      <HeaderContainer>
        <LogoWrapper>
          <Link to="/">
            <CherrypalmLogoHorizontalImg />
          </Link>
        </LogoWrapper>
        <div style={{ display: 'flex' }}>
          <CallNowBtn href="tel:909-529-2772">909-529-2772</CallNowBtn>
          <LinkContainer>
            {navLinks.map(el => (
              <StyledLink key={el.id} to={el.link}>
                {el.text}
              </StyledLink>
            ))}
          </LinkContainer>
          <HeaderMenu />
        </div>
      </HeaderContainer>
    </StyledHeader>
  );
};

export default Header;
