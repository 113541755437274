import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const MapDarkImg = () => {
  const data = useStaticQuery(graphql`
    query {
      mapDarkImg: file(relativePath: { eq: "map_dark.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data.mapDarkImg.childImageSharp.fluid} />;
};

export default MapDarkImg;
